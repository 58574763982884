import React from 'react';
import WordCard from '../components/WordCard';

const Learn = () => {
  return (
    <>
        <WordCard word={"Hier"}/>
        <WordCard word={"Wird gelernt"}/>
    </>
)
};

export default Learn;